<template>
  <app-overlay backdrop>
    <section class="applicant-details" @click.self="handleClose">
      <aside class="applicant-details__nav applicant-details__nav--prev">
        <navigation-arrow
          v-if="previousApplicant"
          :label="previousApplicant.candidate.name"
          :image="previousApplicant.candidate.avatar"
          :to="{
            name: 'ApplicantInterview',
            params: {
              applicantId: previousApplicant.id,
              positionId: $route.params.positionId
            }
          }"
        />
      </aside>
      <main class="applicant-details__content">
        <applicant-title
          v-if="stageOfApplicant"
          :title="stageOfApplicant.label"
        />
        <modal-card
          data-test="applicantCard"
          @close="handleClose"
        >
          <applicant-card-infos :candidate="candidate" />
          <applicant-card-pending v-if="showPending" />
          <applicant-card-refused
            v-else-if="showRefused"
            :refusal-reason="candidateRefusalReason"
          />
          <template v-else>
            <applicant-card-custom-fields
              v-if="hasCustomFields"
              :custom-fields="candidate.customFields"
            />
            <applicant-card-contacts
              :candidate="candidate"
              :external-invite-id="externalInviteId"
            />
            <applicant-card-tabs />
            <router-view class="applicant-details__view" />
          </template>
          <applicant-card-footer />
        </modal-card>
      </main>
      <aside class="applicant-details__nav applicant-details__nav--next">
        <navigation-arrow
          v-if="nextApplicant"
          :label="nextApplicant.candidate.name"
          :image="nextApplicant.candidate.avatar"
          :to="{
            name: 'ApplicantInterview',
            params: {
              applicantId: nextApplicant.id,
              positionId: $route.params.positionId
            }
          }"
          right
        />
      </aside>
    </section>
  </app-overlay>
</template>

<script>
import ModalCard from '@/components/ModalCard'
import ApplicantCardContacts from './components/ApplicantCardContacts'
import ApplicantCardCustomFields from './components/ApplicantCardCustomFields'
import ApplicantCardInfos from './components/ApplicantCardInfos'
import ApplicantCardFooter from './components/ApplicantCardFooter'
import ApplicantCardRefused from './components/ApplicantCardRefused'
import ApplicantCardPending from './components/ApplicantCardPending'
import ApplicantCardTabs from './components/ApplicantCardTabs'
import ApplicantTitle from './components/ApplicantTitle'
import NavigationArrow from '@/components/NavigationArrow'
import { TheOverlay } from '@revelotech/everest'
import stagesConfig from '@/configs/stages'
import {
  showInvitePending,
  showInviteRefused
} from '@/helpers/stages'

import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'

const ApplicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'Applicant',
  components: {
    ApplicantCardContacts,
    ApplicantCardCustomFields,
    ApplicantCardInfos,
    ApplicantCardFooter,
    ApplicantCardRefused,
    ApplicantCardPending,
    ApplicantCardTabs,
    ApplicantTitle,
    ModalCard,
    NavigationArrow
  },
  computed: {
    ...ApplicantsHelper.mapGetters([
      'stageOfApplicant',
      'nextApplicant',
      'previousApplicant',
      'applicant'
    ]),
    candidateRefusalReason () {
      return this.applicant?.candidateRefusalReasonText
    },
    candidate () { return this.applicant?.candidate || {} },
    applicantId () { return this.$route.params.applicantId },
    externalInviteId () { return this.applicant?.externalInviteId },
    showPending () {
      return showInvitePending(this.applicant?.currentState) &&
        this.$route.name !== stagesConfig.refused.route
    },
    showRefused () { return showInviteRefused(this.applicant?.currentState) },
    hasCustomFields () { return this.candidate?.customFields?.length }
  },
  watch: {
    applicantId (newValue) {
      this.setApplicantId(newValue)
    }
  },
  created () {
    this.$gtmTrackEvent('opened_candidate_card')
    this.$smartlook('track', 'opened_candidate_card')
    this.setApplicantId(this.$route.params.applicantId)
    TheOverlay.eventBus.$on('backdropClick', this.handleClose)
  },
  beforeDestroy () {
    TheOverlay.eventBus.$off('backdropClick', this.handleClose)
  },
  methods: {
    ...ApplicantsHelper.mapActions(['setApplicantId']),
    handleClose () {
      this.$router.push({
        name: 'WorkflowBoard',
        params: { positionId: this.$route.params.positionId }
      })
    }
  }
}
</script>

<style lang="scss">
.applicant-details {
  height: 100vh;
  left: 0;
  overflow-y: auto;
  position: relative;
  top: 0;
  width: 100vw;

  &__nav {
    align-items: center;
    background: $blue-light;
    bottom: 0;
    display: none;
    height: 64px;
    justify-content: center;
    position: fixed;
    width: 50vw;
    z-index: 100;

    &--next { left: 50vw; }

    @include breakpoint(lg) {
      background: none;
      display: flex;
      top: 45vh;
      width: 48px;

      &--next { left: calc(100vw - 64px); }
    }
  }

  &__content {
    margin-bottom: 72px;
    width: 100vw;

    @include breakpoint(lg) {
      left: 25vw;
      padding-top: $base*12;
      position: absolute;
      width: 50vw;
    }
  }
}
</style>
