<template>
  <section class="applicant-card-contacts">
    <p class="applicant-card-contacts__title">
      <strong>{{ $t(`${langPath}.title`) }}</strong>
    </p>
    <section class="applicant-card-contacts__main">
      <section v-if="candidate.resumeUrl" class="applicant-card-contacts__contact">
        <p class="applicant-card-contacts__contact-info">
          <a
            class="applicant-card-contacts__link"
            data-test="resume_link"
            target="_blank"
            :href="candidate.resumeUrl"
          >
            <ev-icon
              class="applicant-card-contacts__icon"
              name="file-alt"
              color="blue"
            />
            <span class="applicant-card-contacts__link-text">
              {{ $t(`${langPath}.resumeLabel`, { name: candidateFirstName }) }}
            </span>
          </a>
        </p>
      </section>
      <section v-if="candidate.linkedinUrl" class="applicant-card-contacts__contact">
        <p class="applicant-card-contacts__contact-info">
          <a
            class="applicant-card-contacts__link"
            data-test="linkedin_link"
            target="_blank"
            :href="formatUrl(candidate.linkedinUrl)"
          >
            <ev-icon
              name="linkedin"
              class="applicant-card-contacts__icon applicant-card-contacts__icon-linkedin"
              color="blue"
            />
            <span class="applicant-card-contacts__link-text">
              {{ $t(`${langPath}.linkedinLabel`, { name: candidateFirstName }) }}
            </span>
          </a>
        </p>
      </section>
      <section v-if="candidate.portfolioUrl" class="applicant-card-contacts__contact">
        <p class="applicant-card-contacts__contact-info">
          <a
            class="applicant-card-contacts__link"
            data-test="portfolio_link"
            target="_blank"
            :href="candidate.portfolioUrl"
          >
            <ev-icon name="link" class="applicant-card-contacts__icon" color="blue" />
            <span class="applicant-card-contacts__link-text">
              {{ candidate.portfolioUrl }}
            </span>
          </a>
        </p>
      </section>
      <section v-if="candidate.phone" class="applicant-card-contacts__contact">
        <p class="applicant-card-contacts__contact-info">
          <ev-icon name="phone" class="applicant-card-contacts__icon" color="blue" />
          <span class="applicant-card-contacts__link-text">
            {{ candidate.phone }}
          </span>
        </p>
      </section>
      <section
        v-if="candidate.phone"
        class="applicant-card-contacts__contact"
        data-test="contact-via-whatsapp"
      >
        <a
          class="applicant-card-contacts__contact-info"
          :href="whatsappLinkToCandidate"
          data-test="whatsapp-link"
          data-ga-id="clicked_on_whatsapp_button"
          target="_blank"
        >
          <ev-icon
            name="whatsapp"
            data-test="whatsapp-icon"
            class="applicant-card-contacts__icon"
            color="blue"
          />
          <span class="applicant-card-contacts__link-text">
            {{ $t(`${langPath}.callOnWhatsapp`) }}
          </span>
        </a>
      </section>
      <section class="applicant-card-contacts__contact">
        <p class="applicant-card-contacts__contact-info">
          <a
            class="applicant-card-contacts__contact-info applicant-card-contacts-info__mailto"
            :href="`mailto:${ candidate.email }`"
            data-ga-id="clicked_mailto"
            data-test="mailto"
            @click="trackClick"
          >
            <ev-icon
              name="envelope"
              class="applicant-card-contacts__icon"
              color="blue"
            />
            <span class="applicant-card-contacts__link-text">
              {{ candidate.email }}
            </span>
          </a>
        </p>
      </section>
      <section
        v-if="marketplaceCandidate"
        class="applicant-card-contacts__contact"
        data-test="chat_section"
      >
        <router-link
          data-test="chat_section"
          class="applicant-card-contacts__contact-info"
          target="_blank"
          :to="chatUrl"
        >
          <ev-icon
            name="comment-alt-dots"
            class="applicant-card-contacts__icon"
            color="blue"
          />
          <span
            class="applicant-card-contacts__link-text--chat"
            data-ga-id="clicked_talk_with_candidate"
          >
            {{ $t(`${langPath}.talkTo`) }} {{ candidateFirstName }}
          </span>
        </router-link>
      </section>
    </section>
  </section>
</template>

<script>
import EvIcon from '@revelotech/everestV2/EvIcon'
const wameUrl = 'https://wa.me/'
const brazilianPhoneCode = '55'

export default {
  name: 'ApplicantCardContacts',
  components: {
    EvIcon
  },
  props: {
    candidate: {
      type: Object,
      required: true
    },
    externalInviteId: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    candidateFirstName () {
      return this.candidate?.name?.split(' ')[0]
    },
    marketplaceCandidate () {
      return this.candidate.source === 'marketplace'
    },
    chatUrl () {
      return `/companies/employers/invites/${this.externalInviteId}`
    },
    whatsappLinkToCandidate () {
      const sanitizedPhone = this.candidate?.phone?.replace(/\W|_/g, '')
      return `${wameUrl}${brazilianPhoneCode}${sanitizedPhone}`
    }
  },
  methods: {
    trackClick () {
      this.$smartlook('track', 'clicked_on_mailto')
    },
    formatUrl (link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`
    }
  }
}
</script>

<style lang="scss">
.applicant-card-contacts {
  @include margin('top', 6);

  border-top: solid 1px $gray40;
  padding-top: 6*$base;

  &__main {
    @include margin('top', 6);

    display: grid;
    gap: 4*$base;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-content: flex-start;
  }

  &_title {
    @extend %body-text2;
  }

  &__contact {
    @include margin('right', 12);
  }

  &__contact-info {
    @extend %body-text2;

    display: flex;
  }

  &__icon {
    @include margin('right', 2);
  }

  &__link {
    display: flex;
  }

  &__link-text {
    align-self: center;

    &--chat {
      @extend %body-text1;
    }
  }
}
</style>
