<template>
  <router-link
    class="navigation-arrow"
    :to="to"
  >
    <span class="navigation-arrow__nav">
      <icon-base
        class="navigation-arrow__nav-icon"
        height="32"
        view-box="0 0 64 64"
        width="32"
        :icon-name="sideIconName"
      />
    </span>
    <section
      class="navigation-arrow__infos"
      :class="{
        'navigation-arrow__infos--left': !right,
        'navigation-arrow__infos--right': right
      }"
    >
      <p class="navigation-arrow__infos-label">
        {{ label }}
      </p>
      <img
        class="navigation-arrow__infos-img"
        :alt="label"
        :src="image"
      >
    </section>
  </router-link>
</template>

<script>
import IconBase from '@/components/IconBase'

export default {
  name: 'NavigationArrow',
  components: { IconBase },
  props: {
    image: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    right: {
      type: Boolean,
      default: false
    },
    to: {
      type: [Object, String],
      default: '#'
    }
  },
  computed: {
    sideIconName () {
      return this.right ? 'right-arrow' : 'left-arrow'
    }
  }
}
</script>

<style lang="scss">
.navigation-arrow {
  display: block;
  padding: $base*2;
  position: relative;
  transition: 0.6s;

  &__infos {
    align-items: center;
    background-color: $white;
    height: 100%;
    padding: 0 $base*15;
    position: absolute;
    top: 0;
    transition: 0.6s;
    z-index: -1;

    &-img {
      height: 100%;
      position: absolute;
      top: 0;
    }

    &-label {
      @extend %body-text2;

      align-items: center;
      color: $gray80;
      display: flex;
      height: 100%;
      margin: 0;
      position: relative;
      white-space: nowrap;
    }

    &--left {
      left: 0;
      text-align: right;
      -webkit-transform: translateX(-150%);
      transform: translateX(-150%);

      & > img { right: 0; }
    }

    &--right {
      right: 0;
      -webkit-transform: translateX(150%);
      transform: translateX(150%);

      & > img { left: 0; }
    }

    *:hover > & {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }

  &:hover {
    background: $blue;
  }
}
</style>
