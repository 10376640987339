<template>
  <section class="applicant-refused">
    <div v-if="refusalReason">
      <p
        data-test="reason-subtitle"
        class="applicant-refused__subtitle"
      >
        {{ $t(`${langPath}.refusalReasonTitle`) }}
      </p>
      <p class="applicant-refused__message">
        {{ refusalReason }}
      </p>
    </div>
    <div v-else>
      <p
        data-test="subtitle"
        class="applicant-refused__subtitle"
      >
        {{ $t(`${langPath}.subtitle`) }}
      </p>
      <p class="applicant-refused__message">
        {{ $t(`${langPath}.description`) }}
      </p>
      <p class="applicant-refused__message">
        {{ $t(`${langPath}.toGetInTouch`) }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ApplicantCardRefused',
  props: {
    refusalReason: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.applicant-refused {
  border-top: 1px solid $gray20;
  margin-top: $base*6;
  padding-top: $base*6;

  &__subtitle {
    @extend %subtitle1;
  }

  &__message {
    @extend %body-text1;

    margin-top: $base*3;
  }
}
</style>
