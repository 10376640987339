<template>
  <section class="applicant-pending">
    <p class="applicant-pending__message applicant-pending__message--subtitle">
      {{ $t(`${langPath}.subtitle`) }}
    </p>
    <p class="applicant-pending__message">
      {{ $t(`${langPath}.description`) }}
    </p>
  </section>
</template>

<script>
export default {
  name: 'ApplicantCardPending',
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>
<style lang="scss">
.applicant-pending {
  &__message {
    @extend %body-text1;

    margin-top: $base*3;

    &--subtitle {
      @extend %subtitle1;
    }
  }
}
</style>
