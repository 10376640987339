<template>
  <h2
    class="applicant"
    data-test="applicantTitle"
  >
    {{ $t(`${langPath}.currentStage`) }}: {{ title }}
  </h2>
</template>

<script>
export default {
  name: 'ApplicantTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      langPath: __langpath
    }
  }
}
</script>

<style lang="scss">
.applicant {
  background: $white;
  color: $gray100;
  padding: $base*4;
  width: 100%;

  @include breakpoint(lg) {
    background: none;
    color: $white;
    padding: $base*2 0;
  }
}
</style>
