<template>
  <ul
    v-if="showTabs"
    class="applicant-card-tabs"
    data-test="applicant-card-tabs"
  >
    <router-link
      v-if="showTab('interview')"
      data-ga-id="clicked_on_interview_tab"
      data-test="interview-tab"
      exact
      class="applicant-card-tabs__item"
      tag="li"
      :to="{ name: 'ApplicantInterview' }"
    >
      {{ $t(`${langPath}.interview`) }}
    </router-link>
    <router-link
      v-if="showTab('proposal')"
      data-ga-id="clicked_on_proposal_tab"
      data-test="proposal-tab"
      exact
      class="applicant-card-tabs__item"
      tag="li"
      :to="{ name: 'ApplicantProposal' }"
    >
      {{ $t(`${langPath}.proposal`) }}
    </router-link>
    <router-link
      v-if="showTab('comments')"
      data-ga-id="clicked_on_comments_tab"
      data-test="comments-tab"
      exact
      class="applicant-card-tabs__item"
      tag="li"
      :to="{ name: 'ApplicantComments' }"
    >
      {{ $t(`${langPath}.comment`) }}
    </router-link>
  </ul>
</template>

<script>
import { showTab } from '@/helpers/stages'

import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'

const ApplicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantCardTabs',
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...ApplicantsHelper.mapGetters(['stageOfApplicant']),
    showTabs () {
      return this.$route.params.action !== 'refuse' &&
        this.$route.name !== 'ApplicantRejected'
    }
  },
  methods: {
    showTab (tab) { return showTab(this.stageOfApplicant?.kind, tab) }
  }
}
</script>

<style lang="scss">
.applicant-card-tabs {
  border-bottom: 1px solid $gray40;
  display: flex;
  margin: $base*4 0;

  &__item {
    cursor: pointer;
    padding: $base*4;
    position: relative;

    &.router-link-exact-active {
      font-weight: bold;

      &::after {
        background-color: $red;
        bottom: -1px;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}
</style>
