<template>
  <section class="applicant-actions">
    <ev-button
      class="applicant-actions__button"
      data-test="button-refuse-activate"
      variant="secondary"
      color="blue"
      :disabled="disableRefuse"
      @click="handleReject"
    >
      {{ $t(`${langPath}.reject`) }}
    </ev-button>

    <ev-button
      class="applicant-actions__button"
      data-test="button-next-stage"
      :disabled="disableNextStage"
      @click="handleNextStageClick"
    >
      {{ $t(`${langPath}.nextStage`) }}
    </ev-button>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS, STAGES } from '@/store/namespaces'
import EvButton from '@revelotech/everestV2/EvButton'

const stagesHelper = createNamespacedHelpers(STAGES)
const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantActions',
  components: {
    EvButton
  },
  data () {
    return {
      langPath: __langpath,
      refuseReason: '',
      disableRejectionButton: true
    }
  },
  computed: {
    ...applicantsHelper.mapGetters([
      'applicant',
      'stageOfApplicant'
    ]),
    currentStateApplicantPending () {
      return this.applicant?.currentState === ''
    },
    currentStateApplicantRefused () {
      return this.applicant?.currentState === 'applicant_refused'
    },
    disableRefuse () {
      return this.stageOfApplicant?.kind === 'refused' ||
        !(this.$validator.validate())
    },
    disableNextStage () {
      return (
        ['hired', 'refused'].includes(this.stageOfApplicant?.kind) ||
        this.currentStateApplicantPending ||
        this.currentStateApplicantRefused
      )
    }
  },
  methods: {
    ...stagesHelper.mapActions(['rejectCandidate', 'moveApplicantNextStage']),
    handleReject () {
      this.openApplicant('ApplicantRejected', {
        applicantId: this.applicant.id,
        action: 'refuse'
      })
    },
    async handleNextStageClick () {
      await this.moveApplicantNextStage(this.$route.params.applicantId)
      this.closeForm()
      this.$smartlook('track', 'moved_next_stage')
    },
    closeForm () {
      this.$router.push({
        name: 'WorkflowBoard',
        params: { positionId: this.$route.params.positionId }
      })
    },
    openApplicant (routeName, params) {
      this.$router.push({ name: routeName, params })
    }
  }
}
</script>

<style lang="scss">
.applicant-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;

  &__select {
    @include margin(top, 3);

    width: 100%;
  }

  &__button {
    @include margin(left, 3);
    @include margin(top, 3);
  }
}
</style>
