var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-overlay',{attrs:{"backdrop":""}},[_c('section',{staticClass:"applicant-details",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleClose.apply(null, arguments)}}},[_c('aside',{staticClass:"applicant-details__nav applicant-details__nav--prev"},[(_vm.previousApplicant)?_c('navigation-arrow',{attrs:{"label":_vm.previousApplicant.candidate.name,"image":_vm.previousApplicant.candidate.avatar,"to":{
          name: 'ApplicantInterview',
          params: {
            applicantId: _vm.previousApplicant.id,
            positionId: _vm.$route.params.positionId
          }
        }}}):_vm._e()],1),_c('main',{staticClass:"applicant-details__content"},[(_vm.stageOfApplicant)?_c('applicant-title',{attrs:{"title":_vm.stageOfApplicant.label}}):_vm._e(),_c('modal-card',{attrs:{"data-test":"applicantCard"},on:{"close":_vm.handleClose}},[_c('applicant-card-infos',{attrs:{"candidate":_vm.candidate}}),(_vm.showPending)?_c('applicant-card-pending'):(_vm.showRefused)?_c('applicant-card-refused',{attrs:{"refusal-reason":_vm.candidateRefusalReason}}):[(_vm.hasCustomFields)?_c('applicant-card-custom-fields',{attrs:{"custom-fields":_vm.candidate.customFields}}):_vm._e(),_c('applicant-card-contacts',{attrs:{"candidate":_vm.candidate,"external-invite-id":_vm.externalInviteId}}),_c('applicant-card-tabs'),_c('router-view',{staticClass:"applicant-details__view"})],_c('applicant-card-footer')],2)],1),_c('aside',{staticClass:"applicant-details__nav applicant-details__nav--next"},[(_vm.nextApplicant)?_c('navigation-arrow',{attrs:{"label":_vm.nextApplicant.candidate.name,"image":_vm.nextApplicant.candidate.avatar,"to":{
          name: 'ApplicantInterview',
          params: {
            applicantId: _vm.nextApplicant.id,
            positionId: _vm.$route.params.positionId
          }
        },"right":""}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }