<template>
  <footer
    class="applicant-card-footer"
  >
    <stage-card-current-state
      v-if="currentStateAcceptedProposal"
      modal-footer
      data-test="accepted-proposal"
      current-state="accepted-proposal"
    />
    <stage-card-current-state
      v-else-if="currentStateCompanyRejected"
      data-test="company-rejected-label"
      modal-footer
      current-state="company-rejected"
    />
    <stage-card-current-state
      v-else-if="currentStateApplicantRefused"
      data-test="candidate-refused-label"
      modal-footer
      current-state="applicant-refused"
    />
    <applicant-actions v-else-if="showButtons" />
  </footer>
</template>

<script>
import ApplicantActions
  from './ApplicantActions'
import StageCardCurrentState
  from '@/pages/WorkflowBoard/components/StageCardCurrentState'

import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS } from '@/store/namespaces'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantCardFooter',
  components: {
    ApplicantActions,
    StageCardCurrentState
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...applicantsHelper.mapGetters(['applicant']),
    currentStateAcceptedProposal () {
      return this.applicant?.currentState === 'accepted_proposal'
    },
    currentStateCompanyRejected () {
      return this.applicant?.currentState === 'company_rejected'
    },
    currentStateApplicantRefused () {
      return this.applicant?.currentState === 'applicant_refused'
    },
    showButtons () {
      return !this.currentStateApplicantRefused &&
        this.$route.params?.action !== 'refuse' &&
        !['ApplicantRejected', 'ApplicantProposal'].includes(this.$route.name)
    }
  }
}
</script>

<style lang="scss">
.applicant-card-footer {
  @include margin(top, 8);

  border-top: 1px solid $gray20;
  display: flex;
  justify-content: flex-end;
  padding-top: $base*8;

  &--no-border {
    border-top: 0;
    padding-top: 0;
  }
}
</style>
