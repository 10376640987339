<template>
  <section class="applicant-card-custom-fields">
    <section
      v-for="field in customFields"
      :key="field.id"
      class="applicant-card-custom-fields__field"
    >
      <p class="applicant-card-custom-fields__title" data-test="title-field">
        <strong>{{ field.title }}</strong>
      </p>
      <p class="applicant-card-custom-fields__value">
        {{ field.value }}
      </p>
    </section>
  </section>
</template>

<script>
export default {
  name: 'ApplicantCardCustomFields',
  props: {
    customFields: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.applicant-card-custom-fields {
  @include margin('top', 6);

  border-top: solid 1px $gray40;
  padding-top: 6*$base;

  &__title {
    @include margin('bottom', 4);
  }

  &__value {
    @extend %body-text2;

    color: $gray60;
  }
}
</style>
